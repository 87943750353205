<template>
<div>
    <v-alert type="success" class="copiedTelegram" v-if="copied">
        Quest finish link copied to your clipboard
    </v-alert>
  <h1 class="heading">My Quests</h1>
  <div class="rooms__header">
    <div class="filter__item" style="width:100%;padding: 0; display: flex;justify-content:space-between;">
      <div style="display: flex;">
          <v-autocomplete
            style="padding:0;max-width:300px; margin-right:15px;"
            placeholder="Объект"
            outlined
            dense
            :items="$store.state.objects.list"
            item-text="name"
            v-model="$store.state.objects.item"
            @change="changeHotel"
            return-object
        >
            <template v-slot:selection="{ item }">
                              <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
              <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
              <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
              <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
            </template>
            <template v-slot:item="{ item }">
                              <div v-if="$store.state.isMobile">{{ item.name.slice(0,5) }}...</div>
                <div v-if="!$store.state.isMobile">{{ item.name }}</div>
              <div v-if="item.tariff_id === 1" class="tariff_obj">free</div>
              <div v-if="item.tariff_id === 2" class="tariff_obj">standart</div>
              <div v-if="item.tariff_id === 3" class="tariff_obj">pro</div>
            </template>
          </v-autocomplete>
    </div>
      <v-btn color="primary" outlined dense @click="openAddGroupQuest" small v-if="!active_tab && page === 'group'">+ Add new quest group</v-btn>
      <v-btn color="primary" outlined dense @click="openAddSingleQuest" small v-if="active_tab && page === 'group'">+ Add new quest</v-btn>
      <v-btn v-if="page === 'category'" @click="createCategoryOpen" color="primary"  outlined small><v-icon>mdi-plus</v-icon>Add category</v-btn>
    </div>
  </div>
  <div v-if="page === 'group' || page === 'quests'">
    <v-btn color="primary" @click="backToCategoryList" outlined small style="margin:15px;"><v-icon>mdi-chevron-left</v-icon>Back to category list</v-btn>
    <v-tabs v-model="active_tab">
      <v-tab>Group quests</v-tab>
      <v-tab>Single quests</v-tab>
      <v-tab-item>
            <div v-if="page === 'quests' && selectedGroup" >
                <div style="display:flex;justify-content: space-between;align-items: center">
                    <v-btn color="primary" @click="backToGroupList" outlined small style="margin:15px;"><v-icon>mdi-chevron-left</v-icon>Back to group list</v-btn>
                    <v-btn color="primary" @click="openAddSingleQuest(selectedGroup)" outlined small style="margin:15px;"><v-icon>mdi-plus</v-icon>Add quest</v-btn>
                </div>
              <v-data-table
                  class="table rooms__table"
                  :headers="headersSingle"
                  :search="searchSingle"
                  :items="selectedGroup.quests"
                  hide-default-footer
                  no-data-text="You don't have any quests "
                  :items-per-page="999"
              >
                <template v-slot:body="{ items }">
                  <tbody v-if="items.length">
                  <tr v-for="item in items" :key="item.id">
                    <td class="d-block d-sm-table-cell" style="text-align: center">
                      <img :src="item.main_image?item.main_image:require('@/assets/none.png')" alt="" style="width:50px;height:50px;">
                    </td>
                    <td class="d-block d-sm-table-cell" style="text-align: center">
                      {{item.name['en']}}
                    </td>
                    <td class="d-block d-sm-table-cell" style="text-align: center">
                      {{item.score}}
                    </td>
                    <td class="d-block d-sm-table-cell" style="text-align: center">
                      {{item.limit}}
                    </td>
                    <td class="d-block d-sm-table-cell" style="text-align: center">
                      <v-menu offset-y open-on-hover bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" dark small v-bind="attrs" v-on="on" outlined>
                            <v-icon>mdi-cog</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                              style="cursor: pointer;" @click="editQuestSingleOpen(item)"
                          >
                            <v-list-item-title
                            >Edit quest</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                              style="cursor: pointer;color:red;" @click="deleteQuestSingleOpen(item.id)"
                          >
                            <v-list-item-title
                            >Delete</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td class="d-block d-sm-table-cell" colspan="7" style="text-align: center">
                      <div style="margin-bottom:15px;">You don't have any quests, but you can add</div>
                      <v-btn color="primary" @click="openAddSingleQuest" outlined small style="margin:15px;"><v-icon>mdi-plus</v-icon>Add quest</v-btn>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-data-table>
            </div>
            <div v-else>
              <v-data-table
                  class="table rooms__table"
                  :headers="headersGroup"
                  :search="searchGroup"
                  :items="selectedCategory.quest_groups"
                  hide-default-footer
                  no-data-text="You don't have any quests groups"
                  :items-per-page="999"
              >
                <template v-slot:body="{ items }">
                  <tbody v-if="items.length">
                  <tr v-for="item in items" :key="item.id">
                    <td class="d-block d-sm-table-cell" style="text-align: center">
                      <img :src="item.main_image?item.main_image:require('@/assets/none.png')" alt="" style="width:50px;height:50px;">
                    </td>
                    <td class="d-block d-sm-table-cell" style="text-align: center">
                      {{item.name['en']}}
                    </td>
                    <td class="d-block d-sm-table-cell" style="text-align: center">
                      {{item.quests.length}}
                    </td>
                    <td class="d-block d-sm-table-cell" style="text-align: center">
                      {{item.score}}
                    </td>
                    <td class="d-block d-sm-table-cell" style="text-align: center">
                      <v-menu offset-y open-on-hover bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="primary" dark small v-bind="attrs" v-on="on" outlined>
                            <v-icon>mdi-cog</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                              style="cursor: pointer;"
                          >
                            <v-list-item-title @click="openAddSingleQuest(item)"
                            >Add quest</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                              style="cursor: pointer;"
                          >
                            <v-list-item-title @click="editQuestGroupOpen(item)"
                            >Edit group</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                              style="cursor: pointer;"
                              @click="openQuestList(item)"
                          >
                            <v-list-item-title
                            >Quests list</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item
                              style="cursor: pointer;color:red;" @click="deleteQuestGroupOpen(item.id)"
                          >
                            <v-list-item-title
                            >Delete</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr>
                    <td class="d-block d-sm-table-cell" colspan="5" style="text-align: center;">
                      <div style="margin-bottom:15px;">You don't have any quests groups, but you can add</div>
                      <v-btn color="primary" outlined dense @click="openAddGroupQuest" small>+ Add new quest group</v-btn>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-data-table>
            </div>
      </v-tab-item>
      <v-tab-item>
              <v-data-table
            class="table rooms__table"
            :headers="headersSingle"
            :search="searchSingle"
            :items="selectedCategory.quests"
            hide-default-footer
            no-data-text="You don't have any quests "
            :items-per-page="999"
        >
          <template v-slot:body="{ items }">
            <tbody v-if="items.length">
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell" style="text-align: center">
                <img :src="item.main_image?item.main_image:require('@/assets/none.png')" alt="" style="width:50px;height:50px;">
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                {{item.name['en']}}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                {{item.score}}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                {{item.limit}}
              </td>
              <td class="d-block d-sm-table-cell" style="text-align: center">
                <v-menu offset-y open-on-hover bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark small v-bind="attrs" v-on="on" outlined>
                      <v-icon>mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                      <v-list-item
                              style="cursor: pointer;" @click="editQuestSingleOpen(item)"
                      >
                          <v-list-item-title
                          >Edit quest</v-list-item-title
                          >
                      </v-list-item>
                      <v-list-item
                              style="cursor: pointer;" @click="copyLink(item)"
                      >
                          <v-list-item-title
                          >Copy finish link</v-list-item-title
                          >
                      </v-list-item>
                    <v-list-item
                        style="cursor: pointer;color:red;" @click="deleteQuestSingleOpen(item.id)"
                    >
                      <v-list-item-title
                      >Delete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td class="d-block d-sm-table-cell" colspan="7" style="text-align: center">
                <div style="margin-bottom:15px;">You don't have any quests, but you can add</div>
                <v-btn color="primary" outlined dense @click="openAddSingleQuest" small v-if="active_tab && page === 'group'">+ Add new quest</v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
  </div>
  <div v-if="page === 'category'">
    <h2 style="margin-bottom:15px;">Categories list</h2>
    <v-data-table
              class="table rooms__table"
              :headers="headersCategory"
              :search="searchSingle"
              hide-default-footer
              :items="questCategories"
              :items-per-page="999"
          >
            <template v-slot:body="{ items }">
              <tbody v-if="items.length">
              <tr v-for="item in items" :key="item.id" @click="selectCategory(item)">
                <td class="d-block d-sm-table-cell" style="text-align: center">
                  <img :src="item.main_image?item.main_image:require('@/assets/none.png')" alt="" style="width:50px;height:50px;">
                </td>
                <td class="d-block d-sm-table-cell" style="text-align: center">
                  {{item.name['en']}}
                </td>
                <td class="d-block d-sm-table-cell" style="text-align: center">
                  {{item.quest_groups.length}}
                </td>
                <td class="d-block d-sm-table-cell" style="text-align: center">
                  {{item.quests.length}}
                </td>
                <td class="d-block d-sm-table-cell" style="text-align: center">
                  <v-menu offset-y open-on-hover bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark small v-bind="attrs" v-on="on" outlined>
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                          style="cursor: pointer;"
                      >
                        <v-list-item-title @click="editCategoryOpen(item)"
                        >Edit Category</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                          style="cursor: pointer;"
                      >
                        <v-list-item-title @click.stop="selectCategory(item)"
                        >Open category</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                          v-if="questCategories.length > 1"
                          style="cursor: pointer;color:red;"
                          @click="deleteQuestCategoryOpen(item.id)"
                      >
                        <v-list-item-title
                        >Delete</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td class="d-block d-sm-table-cell" colspan="6" style="text-align: center">
                  You don't have any categories
                </td>
              </tr>
              </tbody>
            </template>
          </v-data-table>
  </div>
  <v-dialog :fullscreen="$store.state.isMobile" v-model="createGroupDialog" max-width="700" eager @click:outside="closeCreateGroupDialog">
    <v-card>
      <v-btn class="popup__close" small icon @click="closeCreateGroupDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        Create new quest group
      </v-card-title>
      <v-card-text>
        <div class="mb-5">
          <label class="label">Main image</label>
          <vue-dropzone
              ref="addGroupDropzone"
              id="addGroupDropzone"
              :options="dropzoneOptions"
              @vdropzone-sending="customEvent"
              @vdropzone-removed-file="handleRemoveAddGroup"
              maxFiles="1"
              @vdropzone-success="handleSuccessAddGroup"
              @vdropzone-max-files-exceeded="logoMaxFileSexceededAddGroup"
          >
          </vue-dropzone>
        </div>
              <v-tabs>
                <v-tab v-for="(selected_lang, i) in selected_langs" :key="i">
                  <img
                      :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                      alt=""
                      style="width: 20px; height: 15px; margin-right: 5px"
                  />
                  {{ selected_lang }}
                </v-tab>
                <v-tab-item v-for="lang in selected_langs" :key="lang">
                  <div class="card_body">
                    <div class="card_body_item">
                      <label for="" class="label">Name</label>
                      <v-text-field dense hide-details outlined v-model="createGroupQuest.name[lang]">

                      </v-text-field>
                    </div>
                    <div class="card_body_item">
                      <label for="" class="label">Description</label>
                      <Editor
                          api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                          :init="options"
                          v-model="createGroupQuest.description[lang]"
                      >
                      </Editor>
                    </div>
                    <div class="card_body_item">

                      <label for="" class="label">Score</label>
                      <v-text-field outlined dense hide-details v-model.number="createGroupQuest.score" style="width:200px;">

                      </v-text-field>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
      </v-card-text>
      <v-card-actions style="display:flex;justify-content:flex-end;padding-bottom:15px;">
        <v-btn @click="createQuestGroupConfirm" outlined small color="primary">Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog :fullscreen="$store.state.isMobile" v-model="editGroupDialog" max-width="700" eager @click:outside="closeEditGroup">
    <v-card>
      <v-btn class="popup__close" small icon @click="closeEditGroup">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        Edit quest group
      </v-card-title>
      <v-card-text>
        <div class="mb-5">
          <label class="label">Main image</label>
          <vue-dropzone
              ref="editGroupDropzone"
              id="editGroupDropzone"
              :options="dropzoneOptions"
              @vdropzone-sending="customEvent"
              @vdropzone-removed-file="handleRemoveEditGroup"
              maxFiles="1"
              @vdropzone-success="handleSuccessEditGroup"
          >
          </vue-dropzone>
        </div>
        <v-tabs>
          <v-tab v-for="(selected_lang, i) in selected_langs" :key="i">
            <img
                :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                alt=""
                style="width: 20px; height: 15px; margin-right: 5px"
            />
            {{ selected_lang }}
          </v-tab>
          <v-tab-item v-for="lang in selected_langs" :key="lang">
            <div class="card_body">
              <div class="card_body_item">
                <label for="" class="label">Name</label>
                <v-text-field dense hide-details outlined v-model="editGroupQuest.name[lang]">

                </v-text-field>
              </div>
              <div class="card_body_item">
                <label for="" class="label">Description</label>
                <Editor
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                    v-model="editGroupQuest.description[lang]"
                >
                </Editor>
              </div>
              <div class="card_body_item">
                <label for="" class="label">Score</label>
                <v-text-field outlined dense hide-details v-model.number="editGroupQuest.score"  style="width:200px;">

                </v-text-field>
              </div>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions style="display:flex;justify-content:flex-end;padding-bottom:15px;">
        <v-btn @click="editQuestGroupConfirm" outlined small color="primary">Edit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog :fullscreen="$store.state.isMobile" v-model="createSingleDialog" max-width="700" eager @click:outside="closeCreateSingle">
    <v-card>
      <v-btn class="popup__close" small icon @click="closeCreateSingle">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        Create new quest
      </v-card-title>
      <v-card-text>
        <v-tabs>
          <v-tab>Quest info</v-tab>
          <v-tab>Finish page</v-tab>
          <v-tab-item style="padding-top:15px;">
            <div class="mb-5">
            <label class="label">Main image</label>
            <vue-dropzone
                ref="addSingleDropzone"
                id="addSingleDropzone"
                :options="dropzoneOptions"
                @vdropzone-sending="customEvent"
                @vdropzone-removed-file="handleRemoveAddSingle"
                maxFiles="1"
                @vdropzone-success="handleSuccessAddSingle"
                @vdropzone-max-files-exceeded="logoMaxFileSexceededAddSingle"
            >
            </vue-dropzone>
            </div>
            <div class="mb-5">
            <label class="label">Detail images</label>
            <vue-dropzone
                ref="addSingleDetailDropzone"
                id="addSingleDetailDropzone"
                :options="dropzoneDetailOptions"
                @vdropzone-sending="customEvent"
                @vdropzone-removed-file="handleRemoveAddSingleDetail"
                maxFiles="3"
                @vdropzone-success="handleSuccessAddSingleDetail"
            >
            </vue-dropzone>
            </div>
            <v-tabs>
              <v-tab v-for="(selected_lang, i) in selected_langs" :key="i">
                <img
                    :src="
                  landing.advanced.langs.find((x) => x.value === selected_lang).flag
                "
                    alt=""
                    style="width: 20px; height: 15px; margin-right: 5px"
                />
                {{ selected_lang }}
              </v-tab>
              <v-tab-item v-for="lang in selected_langs" :key="lang">
                <div class="card_body">
                  <div class="card_body_item">
                    <label for="" class="label">Name</label>
                    <v-text-field dense hide-details outlined v-model="createSingleQuest.name[lang]">

                    </v-text-field>
                  </div>
                  <div class="card_body_item">
                    <label for="" class="label">Description</label>
                    <Editor
                        api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                        :init="options"
                        v-model="createSingleQuest.description[lang]"
                    >
                    </Editor>
                  </div>
                  <div style="display:flex;justify-content:space-between;">
                    <div class="card_body_item" v-if="typeof createSingleQuest.quest_group_id !== 'number'">
                      <label for="" class="label">Score</label>
                      <v-text-field type="number" outlined dense hide-details v-model.number="createSingleQuest.score">
                      </v-text-field>
                    </div>
<!--                    <div class="card_body_item" style="margin: 0 15px;">-->
<!--                      <label for="" class="label">Count</label>-->
<!--                      <v-text-field type="number" outlined dense hide-details v-model.number="createSingleQuest.count">-->

<!--                      </v-text-field>-->
<!--                    </div>-->
                    <div class="card_body_item">
                      <label for="" class="label">Time limit (hours)</label>
                      <v-text-field type="number" outlined dense v-model.number="createSingleQuest.limit"></v-text-field>
<!--                      <v-menu-->
<!--                          ref="createSingleTimeDialog"-->
<!--                          v-model="createSingleTimeOpen"-->
<!--                          :close-on-content-click="false"-->
<!--                          :nudge-right="40"-->
<!--                          :return-value.sync="createSingleQuest.limit"-->
<!--                          transition="scale-transition"-->
<!--                          offset-y-->
<!--                          max-width="290px"-->
<!--                          min-width="290px"-->
<!--                      >-->
<!--                        <template v-slot:activator="{ on, attrs }">-->
<!--                          <v-text-field-->
<!--                              v-model="createSingleQuest.limit"-->
<!--                              outlined-->
<!--                              dense-->
<!--                              hide-details-->
<!--                              append-icon="mdi-clock-time-four-outline"-->
<!--                              readonly-->
<!--                              v-bind="attrs"-->
<!--                              v-on="on"-->
<!--                          ></v-text-field>-->
<!--                        </template>-->
<!--                        <v-time-picker-->
<!--                            v-if="createSingleTimeOpen"-->
<!--                            v-model="createSingleQuest.limit"-->
<!--                            full-width-->
<!--                            format="24hr"-->
<!--                            @click:minute="$refs['createSingleTimeDialog'][0].save(createSingleQuest.limit)"-->
<!--                        ></v-time-picker>-->
<!--                      </v-menu>-->
                    </div>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs>
        </v-tab-item>
        <v-tab-item style="padding-top:15px;">
          <div class="mb-5">
            <label class="label" style="display: block;margin-bottom:10px;">Image</label>
            <vue-dropzone
                ref="finishPageCreateDropzone"
                id="finishPageCreateDropzone"
                :options="dropzoneOptions"
                @vdropzone-sending="customEvent"
                @vdropzone-removed-file="handleRemoveFinishPageCreate"
                maxFiles="1"
                @vdropzone-success="handleSuccessFinishPageCreate"
                @vdropzone-max-files-exceeded="logoMaxFileSexceededFinishPageCreate"
            >
            </vue-dropzone>
          </div>
          <v-tabs>
            <v-tab v-for="(selected_lang, i) in selected_langs" :key="i">
              <img
                  :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                  alt=""
                  style="width: 20px; height: 15px; margin-right: 5px"
              />
              {{ selected_lang }}
            </v-tab>
            <v-tab-item v-for="lang in selected_langs" :key="lang" style="padding-top:15px;">
              <div class="card_body_item">
                <label class="label" style="display: block;margin-bottom:10px;">Content</label>
                <Editor
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                    v-model="createSingleQuest.finish_page[lang]"
                >
                </Editor>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions style="display:flex;justify-content:flex-end;padding-bottom:15px;">
        <v-btn @click="createSingleQuestConfirm()" outlined small color="primary">Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog :fullscreen="$store.state.isMobile" v-model="editSingleDialog" max-width="700" eager @click:outside="closeEditSingle">
    <v-card>
      <v-btn class="popup__close" small icon @click="closeEditSingle">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        Edit quest
      </v-card-title>
      <v-card-text style="">
        <v-tabs>
          <v-tab>Quest info</v-tab>
          <v-tab>Finish page</v-tab>
          <v-tab-item style="padding-top:15px;">
            <div class="mb-5">
              <label class="label">Main image</label>
              <vue-dropzone
                  ref="editSingleDropzone"
                  id="editSingleDropzone"
                  :options="dropzoneOptions"
                  @vdropzone-sending="customEvent"
                  @vdropzone-removed-file="handleRemoveEditSingle"
                  maxFiles="1"
                  @vdropzone-success="handleSuccessEditSingle"
                  @vdropzone-max-files-exceeded="logoMaxFileSexceededEditSingle"
              >
              </vue-dropzone>
            </div>
            <div class="mb-5">
              <label class="label">Detail images</label>
              <vue-dropzone
                  ref="editSingleDetailDropzone"
                  id="editSingleDetailDropzone"
                  :options="dropzoneDetailOptions"
                  @vdropzone-sending="customEvent"
                  @vdropzone-removed-file="handleRemoveEditSingleDetail"
                  maxFiles="3"
                  @vdropzone-success="handleSuccessEditSingleDetail"
              >
              </vue-dropzone>
            </div>
            <v-tabs>
              <v-tab v-for="(selected_lang, i) in selected_langs" :key="i">
                <img
                    :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                    alt=""
                    style="width: 20px; height: 15px; margin-right: 5px"
                />
                {{ selected_lang }}
              </v-tab>
              <v-tab-item v-for="lang in selected_langs" :key="lang">
                <div class="card_body">
                  <div class="card_body_item">
                    <label for="" class="label">Name</label>
                    <v-text-field dense hide-details outlined v-model="editSingleQuest.name[lang]">

                    </v-text-field>
                  </div>
                  <div class="card_body_item">
                    <label for="" class="label">Description</label>
                    <Editor
                        api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                        :init="options"
                        v-model="editSingleQuest.description[lang]"
                    >
                    </Editor>
                  </div>
                  <div class="card_body_item">
                    <label for="" class="label">Score</label>
                    <v-text-field outlined dense hide-details v-model.number="editSingleQuest.score">

                    </v-text-field>
                  </div>
<!--                  <div class="card_body_item">-->
<!--                    <label for="" class="label">Count</label>-->
<!--                    <v-text-field outlined dense hide-details v-model.number="editSingleQuest.count">-->

<!--                    </v-text-field>-->
<!--                  </div>-->
                  <div class="card_body_item">
                    <label for="" class="label">Time limit</label>
                    <v-menu
                        ref="editSingleTimeDialog"
                        v-model="editSingleTimeOpen"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="editTime"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            outlined
                            dense
                            v-model="editTime"
                            hide-details
                            append-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                          v-if="editSingleTimeOpen"
                          v-model="editTime"
                          full-width
                          format="24hr"
                          @click:minute="$refs['editSingleTimeDialog'][0].save(editTime)"
                      ></v-time-picker>
                    </v-menu>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-tab-item>
          <v-tab-item style="padding-top:15px;">
            <div class="mb-5">
              <label class="label" style="display: block;margin-bottom:10px;">Image</label>
              <vue-dropzone
                  ref="finishPageEditDropzone"
                  id="finishPageEditDropzone"
                  :options="dropzoneOptions"
                  @vdropzone-sending="customEvent"
                  @vdropzone-removed-file="handleRemoveFinishPageEdit"
                  maxFiles="1"
                  @vdropzone-success="handleSuccessFinishPageEdit"
                  @vdropzone-max-files-exceeded="logoMaxFileSexceededFinishPageEdit"
              >
              </vue-dropzone>
            </div>
            <v-tabs>
              <v-tab v-for="(selected_lang, i) in selected_langs" :key="i">
                <img
                    :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                    alt=""
                    style="width: 20px; height: 15px; margin-right: 5px"
                />
                {{ selected_lang }}
              </v-tab>
              <v-tab-item v-for="lang in selected_langs" :key="lang" style="padding-top:15px;">
                <div class="card_body_item">
                  <label class="label" style="display: block;margin-bottom:10px;">Content</label>
                  <Editor
                      api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                      :init="options"
                      v-model="editSingleQuest.finish_page[lang]"
                  >
                  </Editor>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-tab-item>
        </v-tabs>

      </v-card-text>
      <v-card-actions style="display:flex;justify-content:flex-end;padding-bottom:15px;">
        <v-btn @click="editSingleQuestConfirm" outlined small color="primary">Edit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog :fullscreen="$store.state.isMobile" v-model="createCategoryDialog" max-width="700" eager @click:outside="closeCreateCategory">
    <v-card>
      <v-btn class="popup__close" small icon @click="closeCreateCategory">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        Create new category
      </v-card-title>
      <v-card-text>
            <div class="mb-5">
              <label class="label">Main image</label>
              <vue-dropzone
                  ref="addCategoryDropzone"
                  id="addCategoryDropzone"
                  :options="dropzoneOptions"
                  @vdropzone-sending="customEvent"
                  @vdropzone-removed-file="handleRemoveAddCategory"
                  maxFiles="1"
                  @vdropzone-success="handleSuccessAddCategory"
                  @vdropzone-max-files-exceeded="logoMaxFileSexceededAddCategory"
              >
              </vue-dropzone>
            </div>
            <v-tabs>
              <v-tab v-for="(selected_lang, i) in selected_langs" :key="i">
                <img
                    :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                    alt=""
                    style="width: 20px; height: 15px; margin-right: 5px"
                />
                {{ selected_lang }}
              </v-tab>
              <v-tab-item v-for="lang in selected_langs" :key="lang">
                <div class="card_body">
                  <div class="card_body_item">
                    <label for="" class="label">Name</label>
                    <v-text-field dense hide-details outlined v-model="createCategory.name[lang]">

                    </v-text-field>
                  </div>
                  <div class="card_body_item">
                    <label for="" class="label">Description</label>
                    <Editor
                        api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                        :init="options"
                        v-model="createCategory.description[lang]"
                    >
                    </Editor>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs>
      </v-card-text>
      <v-card-actions style="display:flex;justify-content:flex-end;padding-bottom:15px;">
        <v-btn @click="createCategoryConfirm" outlined small color="primary">Create</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog :fullscreen="$store.state.isMobile" v-model="editCategoryDialog" max-width="700" eager @click:outside="closeEditCategory">
    <v-card>
      <v-btn class="popup__close" small icon @click="closeEditCategory">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        Edit category
      </v-card-title>
      <v-card-text>
        <div class="mb-5">
          <label class="label">Main image</label>
          <vue-dropzone
              ref="editCategoryDropzone"
              id="editCategoryDropzone"
              :options="dropzoneOptions"
              @vdropzone-sending="customEvent"
              @vdropzone-removed-file="handleRemoveEditCategory"
              maxFiles="1"
              @vdropzone-success="handleSuccessEditCategory"
              @vdropzone-max-files-exceeded="logoMaxFileSexceededEditCategory"
          >
          </vue-dropzone>
        </div>
        <v-tabs>
          <v-tab v-for="(selected_lang, i) in selected_langs" :key="i">
            <img
                :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                alt=""
                style="width: 20px; height: 15px; margin-right: 5px"
            />
            {{ selected_lang }}
          </v-tab>
          <v-tab-item v-for="lang in selected_langs" :key="lang">
            <div class="card_body">
              <div class="card_body_item">
                <label for="" class="label">Name</label>
                <v-text-field dense hide-details outlined v-model="editCategory.name[lang]">

                </v-text-field>
              </div>
              <div class="card_body_item">
                <label for="" class="label">Description</label>
                <Editor
                    api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                    :init="options"
                    v-model="editCategory.description[lang]"
                >
                </Editor>
              </div>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions style="display:flex;justify-content:flex-end;padding-bottom:15px;">
        <v-btn @click="editCategoryConfirm" outlined small color="primary">Edit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog :fullscreen="$store.state.isMobile" v-model="finishPageDialog" max-width="700" eager @click:outside="closeFinishPage">
    <v-card>
      <v-btn class="popup__close" small icon @click="closeFinishPage">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>
        Edit finish page
      </v-card-title>
      <v-card-text v-if="finishPage">
        <v-tabs style="margin-bottom:15px;">
          <v-tab v-for="(selected_lang, i) in selected_langs" :key="i">
            <img
                :src="
              landing.advanced.langs.find((x) => x.value === selected_lang).flag
            "
                alt=""
                style="width: 20px; height: 15px; margin-right: 5px"
            />
            {{ selected_lang }}
          </v-tab>
          <v-tab-item v-for="lang in selected_langs" :key="lang">
            <Editor
                api-key="kl7jtag2sjvm09s3lu4cg4f4darvjw7q8w1o817ga0ta0omh"
                :init="options"
                v-model="finishPage.text[lang]"
            >
            </Editor>
          </v-tab-item>
        </v-tabs>
        <v-btn @click="editFinishPageConfirm" outlined small color="primary">Edit</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
  <PopupSuccess ref="PopupSuccess" :title="successTitle"/>
  <PopupDelete ref="PopupDeleteQuestGroup" @confirm="deleteQuestGroupConfirm" :title="'Are you sure about delete quest group?'"/>
  <PopupDelete ref="PopupDeleteQuestSingle" @confirm="deleteQuestSingleConfirm" :title="'Are you sure about delete quest group?'"/>
  <PopupDelete ref="PopupDeleteQuestCategory" @confirm="deleteQuestCategoryConfirm" :title="'Are you sure about delete quest category?'"/>
</div>
</template>

<script>
import titleMixin from "@/mixins/titleMixin";
import store from "@/store"
import {mapActions, mapState,mapMutations} from "vuex";
import moment from "moment"
import PopupSuccess from "../components/Popup/PopupSuccess";
import PopupDelete from "../components/Popup/PopupDelete";
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "Quests",
  components: {PopupDelete, PopupSuccess,vueDropzone},
  mixins:[titleMixin],
  title(){
    return 'My quests'
  },
  beforeRouteEnter (to, from, next) {
    if(!store.state.objects.list.length){
      store.dispatch('objects/getList')
          .then((data) => {
            next();
          }).catch(() => {
        next();
      })
    }else{
      next();
    }
  },
  data(){
    return{
      copied:false,
      createHours:24,
      createMinutes:0,
      finishPageDialog:false,
      dropzoneOptions: {
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        destroyDropzone: true,
        maxFiles: 1,
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-file";
        },
      },
      dropzoneDetailOptions:{
        thumbnailMethod: "contain",
        acceptedFiles: "image/*",
        addRemoveLinks: true,
        destroyDropzone: true,
        maxFiles: 3,
        url: () => {
          return process.env.VUE_APP_API_URL + "upload-file";
        },
      },
      deleteCategoryId:null,
      page:"category",
      headersCategory:[
        { text: "Image", align: "center", sortable: true },
        { text: "Name", align: "center", sortable: true },
        { text: "Groups count", align: "center", sortable: false },
        { text: "Quests count", align: "center", sortable: false },
        { text: "Options", align: "center", sortable: false },
      ],
      defaultCategory:{
        name:{
          en:'default name'
        },
        description:{
          en:'default description'
        },
        main_image:"",
        hotel_id:null,
      },
      createCategory:{
        name:{
          en:'default name'
        },
        description:{
          en:'default description'
        },
        main_image:"",
        hotel_id:null,
      },
      editCategory:{
        name:{
          en:'default name'
        },
        description:{
          en:'default description'
        },
        main_image:"",
        hotel_id:null,
      },
      selectedGroup:null,
      editTime:"",
      editSingleTimeOpen:false,
      editSingleDialog:false,
      editGroupDialog:false,
      successTitle:"",
      createSingleTimeOpen:false,
      deleteGroupId:null,
      deleteSingleId:null,
      createGroupDialog:false,
      createSingleDialog:false,
      active_tab:0,
      searchGroup:"",
      options: {
        images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
        images_file_types: "jpg,svg,webp,gif,png",
        images_upload_handler: async (blobInfo, success, failure) => {
          let formData = new FormData();
          let _file = new File([blobInfo.blob()], blobInfo.filename(), {
            lastModified: new Date().getTime(),
            type: blobInfo.blob().type,
          });
          formData.append(
              "lending_settings_id",
              this.$store.state.landing.settings_id
          );
          formData.append("image", _file);
          formData.append("file", _file);
          const { data } = await this.$axios.post(
              `${process.env.VUE_APP_API_URL}upload-image`,
              formData
          );
          success(data);
        },
        plugins:
            "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount  imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap quickbars emoticons advtable export",

        mobile: {
          images_upload_url: `${process.env.VUE_APP_API_URL}upload-image`,
          images_file_types: "jpg,svg,webp,gif,png",
          images_upload_handler: async (blobInfo, success, failure) => {
            let formData = new FormData();
            let _file = new File([blobInfo.blob()], blobInfo.filename(), {
              lastModified: new Date().getTime(),
              type: blobInfo.blob().type,
            });
            formData.append(
                "lending_settings_id",
                this.$store.state.landing.settings_id
            );
            formData.append("image", _file);
            formData.append("file", _file);
            const { data } = await this.$axios.post(
                `${process.env.VUE_APP_API_URL}upload-image`,
                formData
            );
            success(data);
          },
          plugins:
              "print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount textpattern noneditable help formatpainter pageembed charmap quickbars emoticons advtable",
        },
        menu: {
          tc: {
            title: "Comments",
            items: "addcomment showcomments deleteallconversations",
          },
        },
        menubar: "file edit view insert format tools table tc help",
        toolbar:
            "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | numlist bullist checklist | forecolor backcolor casechange   | charmap emoticons | insertfile image | custom_button",
        autosave_ask_before_unload: true,min_height:200,
        autosave_interval: "30s",
        autosave_prefix: "{path}{query}-{id}-",
        autosave_restore_when_empty: false,
        autosave_retention: "2m",
        image_advtab: true,
        link_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_list: [
          { title: "My page 1", value: "https://www.tiny.cloud" },
          { title: "My page 2", value: "http://www.moxiecode.com" },
        ],
        image_class_list: [
          { title: "None", value: "" },
          { title: "Some class", value: "class-name" },
        ],
        importcss_append: true,
        templates: [
          {
            title: "New Table",
            description: "creates a new table",
            content:
                '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td class="d-block d-sm-table-cell"> </td><td class="d-block d-sm-table-cell"> </td></tr></table></div>',
          },
          {
            title: "Starting my story",
            description: "A cure for writers block",
            content: "Once upon a time...",
          },
          {
            title: "New list with dates",
            description: "New List with dates",
            content:
                '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
          },
        ],
        template_cdate_format: "[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]",
        template_mdate_format: "[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]",
        height: 200,
        image_caption: true,
        quickbars_selection_toolbar:
            "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        content_style: ".mymention{ color: gray; }",
        contextmenu: "link image imagetools table configurepermanentpen",
        skin: "oxide",

        setup: function (editor) {
          editor.ui.registry.addButton("custom_button", {
            text: "Add Button",
            onAction: function () {
              // Open a Dialog
              editor.windowManager.open({
                title: "Add custom button",
                body: {
                  type: "panel",
                  items: [
                    {
                      type: "input",
                      name: "button_label",
                      label: "Button label",
                      flex: true,
                    },
                    {
                      type: "input",
                      name: "button_href",
                      label: "Button href",
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_target",
                      label: "Target",
                      items: [
                        { text: "None", value: "" },
                        { text: "New window", value: "_blank" },
                        { text: "Self", value: "_self" },
                        { text: "Parent", value: "_parent" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_rel",
                      label: "Rel",
                      items: [
                        { text: "No value", value: "" },
                        { text: "Alternate", value: "alternate" },
                        { text: "Help", value: "help" },
                        { text: "Manifest", value: "manifest" },
                        { text: "No follow", value: "nofollow" },
                        { text: "No opener", value: "noopener" },
                        { text: "No referrer", value: "noreferrer" },
                        { text: "Opener", value: "opener" },
                      ],
                      flex: true,
                    },
                    {
                      type: "selectbox",
                      name: "button_style",
                      label: "Style",
                      items: [
                        { text: "Success", value: "success" },
                        { text: "Info", value: "info" },
                        { text: "Warning", value: "warning" },
                        { text: "Error", value: "error" },
                      ],
                      flex: true,
                    },
                  ],
                },
                onSubmit: function (api) {
                  var html =
                      '<a href="' +
                      api.getData().button_href +
                      '" class="v-btn v-btn--outlined theme--light v-size--small ' +
                      api.getData().button_style +
                      '--text" rel="' +
                      api.getData().button_rel +
                      '" target="' +
                      api.getData().button_target +
                      '">' +
                      api.getData().button_label +
                      "</a>";

                  // insert markup
                  editor.insertContent(html);

                  // close the dialog
                  api.close();
                },
                buttons: [
                  {
                    text: "Close",
                    type: "cancel",
                    onclick: "close",
                  },
                  {
                    text: "Insert",
                    type: "submit",
                    primary: true,
                    enabled: false,
                  },
                ],
              });
            },
          });
        },
        content_css: ["/custom_tinymce.css"],
      },
      defaultSingleQuest:{
        name:{
          en:"default name"
        },
        finish_page:{
          en:'default finish page'
        },
        finish_page_image:null,
        main_image:"",
        detail_images:[],
        quest_category_id:null,
        description:{
          en:"default description"
        },
        score:10,
        quest_group_id:null,
        count:1,
        limit:1
      },
      createSingleQuest:{
        name:{
          en:"default name"
        },
        finish_page:{
          en:'default finish page'
        },
        finish_page_image:null,
        main_image:"",
        detail_images:[],
        quest_category_id:null,
        description:{
          en:"default description"
        },
        score:10,
        count:1,
        quest_group_id:null,
        limit:1
      },
      editSingleQuest:{
        name:{
          en:"default name"
        },
        finish_page:{
          en:'default finish page'
        },
        finish_page_image:null,
        description:{
          en:"default description"
        },
        main_image:"",
        detail_images:[],
        quest_group_id:null,
        quest_category_id:null,
        score:10,
        count:1,
        limit:1
      },
      defaultGroupQuest:{
        name:{
          en:"default name"
        },
        quest_category_id:null,
        description:{
          en:"default description"
        },
        score:0,
      },
      createGroupQuest:{
        name:{
          en:""
        },
        quest_category_id:null,
        description:{
          en:""
        },
        score:0,
      },
      editGroupQuest:{
        name:{
          en:""
        },
        quest_category_id:null,
        description:{
          en:""
        },
        score:0,
      },
      headersGroup:[
        { text: "Image", align: "center", sortable: true },
        { text: "Name", align: "center", sortable: true },
        { text: "Quests count", align: "center", sortable: false },
        { text: "Score", align: "center", sortable: false },
        { text: "Options", align: "center", sortable: false },
      ],
      searchSingle:"",
      createCategoryDialog:false,
      editCategoryDialog:false,
      headersSingle:[
        { text: "Image", align: "center", sortable: true },
        { text: "Name", align: "center", sortable: true },
        { text: "Score", align: "center", sortable: true },
        { text: "Limit (hours)", align: "center", sortable: true },
        { text: "Options", align: "center", sortable: true },
      ]
    }
  },
  computed:{
    ...mapState('quests',['questsList','questCategories','selectedCategory','finishPage']),
    ...mapState("landing", ["selected_langs"]),
    ...mapState(["landing"]),
    hoursCount(){
      const hours = [];
      for(let i = 0;i<1000;i++){
        hours.push(i);
      }
      return hours
    },
    minutesCount(){
      const minutes = [];
      for(let i = 0;i<61;i++){
        minutes.push(i)
      }
      return minutes
    }
  },
  methods:{
    ...mapActions('quests',['editFinishPage','getQuestsList','createQuestGroup','createSingleQuestFunc','deleteQuestGroup','editQuestGroup','deleteQuestSingle','editQuestSingle','getQuestCategories','editQuestCategory','deleteQuestCategory','createQuestCategory']),
    ...mapMutations('quests',['setSelectedCategory']),
    copyLink(item){
     this.copied = true;
      navigator.clipboard.writeText(process.env.VUE_APP_LINK+`/${this.$store.state.objects.item.id}?c=${item.token}`);
     setTimeout(() => {
       this.copied = false;
     },3000)
    },
   handleRemoveFinishPageEdit(){
     this.editSingleQuest.finish_page_image = null;
   },
    handleSuccessFinishPageEdit(file){
      this.editSingleQuest.finish_page_image = file.xhr.response;
    },
    logoMaxFileSexceededFinishPageEdit(file){
      this.$refs.finishPageEditDropzone.removeAllFiles();
      this.$refs.finishPageEditDropzone.addFile(file);
    },
    handleRemoveFinishPageCreate(){
      this.createSingleQuest.finish_page_image = null;
    },
    logoMaxFileSexceededFinishPageCreate(file){
      this.$refs.finishPageCreateDropzone.removeAllFiles();
      this.$refs.finishPageCreateDropzone.addFile(file);
    },
    handleSuccessFinishPageCreate(file){
      this.createSingleQuest.finish_page_image = file.xhr.response;
    },
    closeFinishPage(){
      this.finishPageDialog = false;
    },
    async editFinishPageConfirm(){
      await this.editFinishPage(this.finishPage);
      this.successTitle = 'Your finish page successfully edited!';
      this.finishPageDialog = false;
      this.$refs.PopupSuccess.open();
    },
    openFinishModal(){
      this.finishPageDialog = true;
    },
    async changeHotel(){
      await this.getQuestCategories(this.$store.state.objects.item.id);
    },
    handleRemoveEditSingleDetail(file){
      const candidate = this.editSingleQuest.detail_images.find(x => x === file?.xhr?.response || x === file.name);
      if(candidate){
        this.editSingleQuest.detail_images.splice(this.editSingleQuest.detail_images.indexOf(candidate),1);
      }
    },
    handleSuccessEditSingleDetail(file){
      this.editSingleQuest.detail_images.push(file.xhr.response);
    },
    handleRemoveEditSingle(){
      this.editSingleQuest.main_image = "";
    },
    handleSuccessEditSingle(file){
      this.editSingleQuest.main_image = file.xhr.response;
    },
    logoMaxFileSexceededEditSingle(file){
      this.$refs.editSingleDropzone.removeAllFiles();
      this.$refs.editSingleDropzone.addFile(file);
    },
    handleRemoveEditCategory(){
      this.editCategory.main_image = '';
    },
    handleSuccessEditCategory(file){
      this.editCategory.main_image = file.xhr.response;
    },
    logoMaxFileSexceededEditCategory(file){
      this.$refs.editCategoryDropzone.removeAllFiles();
      this.$refs.editCategoryDropzone.addFile(file);
    },
    handleRemoveAddSingleDetail(file){
      const candidate = this.createSingleQuest.detail_images.find(x => x === file?.xhr?.response || x === file.name);
      if(candidate){
        this.createSingleQuest.detail_images.splice(this.createSingleQuest.detail_images.indexOf(candidate),1);
      }
    },
    handleSuccessAddSingleDetail(file){
      this.createSingleQuest.detail_images.push(file.xhr.response);
    },
    handleRemoveAddSingle(){
      this.createSingleQuest.main_image = '';
    },
    handleSuccessAddSingle(file){
      this.createSingleQuest.main_image = file.xhr.response;
    },
    logoMaxFileSexceededAddSingle(file){
      this.$refs.addSingleDropzone.removeAllFiles();
      this.$refs.addSingleDropzone.addFile(file);
    },
    handleRemoveEditGroup(){
      this.editGroupQuest.main_image = '';
    },
    handleSuccessEditGroup(file){
      this.editGroupQuest.main_image = file.xhr.response;
    },
    handleRemoveAddGroup(){
      this.createGroupQuest.main_image = '';
    },
    handleSuccessAddGroup(file){
      this.createGroupQuest.main_image = file.xhr.response;
    },
    logoMaxFileSexceededAddGroup(file){
      this.$refs.addGroupDropzone.removeAllFiles();
      this.$refs.addGroupDropzone.addFile(file);
    },
    handleRemoveAddCategory(){
      this.createCategory.main_image = '';
    },
    handleSuccessAddCategory(file){
      this.createCategory.main_image = file.xhr.response;
    },
    logoMaxFileSexceededAddCategory(file){
      this.$refs.addCategoryDropzone.removeAllFiles();
      this.$refs.addCategoryDropzone.addFile(file);
    },
    async customEvent(_file, xhr, formData) {
      xhr.setRequestHeader(
          "Authorization",
          `Bearer ${this.$store.state.token}`
      );
      formData.append(
          "hotel_id",
          this.$store.state.objects.item.id
      );
      formData.append("image", _file);
    },
    closeEditCategory(){
      this.editCategoryDialog = false;
    },
    editCategoryOpen(category){
      this.editCategory = JSON.parse(JSON.stringify(category));
      this.editCategoryDialog = true;
      this.$nextTick(() => {
        if (this.editCategory.main_image&&this.$refs.editCategoryDropzone) {
          this.$refs.editCategoryDropzone.removeAllFiles();
          let mockFile = {
            accepted: true,
            kind: "image",
          };
          this.$refs.editCategoryDropzone.manuallyAddFile(
              mockFile,
              this.editCategory.main_image
          );
          this.$refs.editCategoryDropzone.dropzone.emit(
              "thumbnail",
              mockFile,
              this.editCategory.main_image
          );
          this.$refs.editCategoryDropzone.dropzone.emit("complete", mockFile);
        }else{
          this.$refs.editCategoryDropzone.removeAllFiles();
        }
      })
    },
    async editCategoryConfirm(){
      await this.editQuestCategory(this.editCategory);
      this.successTitle = 'You category successfully edited!';
      this.editCategoryDialog = false;
      this.$refs.PopupSuccess.open();
    },
    createCategoryOpen(){
      if(this.$refs.addCategoryDropzone){
        this.$refs.addCategoryDropzone.removeAllFiles();
      }
      this.createCategory = JSON.parse(JSON.stringify(this.defaultCategory));
      this.createCategoryDialog = true;
    },
    async createCategoryConfirm(){
      this.createCategory.hotel_id = this.$store.state.objects.item.id;
      await this.createQuestCategory(this.createCategory);
      this.successTitle = 'You category successfully edited!';
      this.createCategoryDialog = false;
      this.$refs.PopupSuccess.open();
    },
    closeCreateCategory(){
      this.createCategoryDialog = false;
    },
    deleteQuestCategoryOpen(id){
      this.deleteCategoryId = id;
      this.$refs.PopupDeleteQuestCategory.open();
    },
    async deleteQuestCategoryConfirm(){
      const payload = {
        hotel_id:this.$store.state.objects.item.id,
        id:this.deleteCategoryId
      }
      await this.deleteQuestCategory(payload);
    },
    backToCategoryList(){
      this.page = 'category';
      this.setSelectedCategory(null);
    },
    selectCategory(item){
      this.setSelectedCategory(item);
      this.page = 'group';
    },
    backToGroupList(){
      this.page = 'group';
      this.selectedGroup = null;
    },
    openQuestList(group){
      this.selectedGroup = group;
      this.page = 'quests';
    },
    async editQuestGroupConfirm(){
      await this.editQuestGroup(this.editGroupQuest);
      this.successTitle = 'You quest group successfully edited!';
      this.editGroupDialog = false;
      this.$refs.PopupSuccess.open();
    },
    closeEditGroup(){
      this.editGroupDialog = false;
    },
    closeEditSingle(){
      this.editSingleDialog = false;
    },
    async editSingleQuestConfirm(){
      await this.editQuestSingle(this.editSingleQuest);
      this.successTitle = 'You quest successfully edited!';
      this.editSingleDialog = false;
      this.$refs.PopupSuccess.open();
    },
    async editQuestSingleOpen(quest){
      this.editSingleQuest = JSON.parse(JSON.stringify(quest));
      this.editSingleDialog = true;
      this.$nextTick(() => {
        if (this.editSingleQuest.main_image&&this.$refs.editSingleDropzone) {
          this.$refs.editSingleDropzone.removeAllFiles();
          let mockFile = {
            accepted: true,
            kind: "image",
          };
          this.$refs.editSingleDropzone.manuallyAddFile(
              mockFile,
              this.editSingleQuest.main_image
          );
          this.$refs.editSingleDropzone.dropzone.emit(
              "thumbnail",
              mockFile,
              this.editSingleQuest.main_image
          );
          this.$refs.editSingleDropzone.dropzone.emit("complete", mockFile);
        }else{
          this.$refs.editSingleDropzone.removeAllFiles();
        }

        if (this.editSingleQuest.detail_images.length&&this.$refs.editSingleDetailDropzone) {
          this.$refs.editSingleDetailDropzone.removeAllFiles();
          for(let i =0;i<this.editSingleQuest.detail_images.length;i++){
            let mockFile = {
              accepted: true,
              kind: "image",
              name:this.editSingleQuest.detail_images[i]
            };
            this.$refs.editSingleDetailDropzone.manuallyAddFile(
                mockFile,
                this.editSingleQuest.detail_images[i]
            );
            this.$refs.editSingleDetailDropzone.dropzone.emit(
                "thumbnail",
                mockFile,
                this.editSingleQuest.detail_images[i]
            );
            this.$refs.editSingleDetailDropzone.dropzone.emit("complete", mockFile);
          }
        }else{
          this.$refs.editSingleDetailDropzone.removeAllFiles();
        }
      })
    },
    async editQuestGroupOpen(questGroup){
      this.editGroupQuest = JSON.parse(JSON.stringify(questGroup));
      this.editGroupDialog = true;
      this.$nextTick(() => {
        if (this.editGroupQuest.main_image && this.$refs.editGroupDropzone) {
          this.$refs.editGroupDropzone.removeAllFiles();
          let mockFile = {
            accepted: true,
            kind: "image",
          };
          this.$refs.editGroupDropzone.manuallyAddFile(
              mockFile,
              this.editGroupQuest.main_image
          );
          this.$refs.editGroupDropzone.dropzone.emit(
              "thumbnail",
              mockFile,
              this.editGroupQuest.main_image
          );
          this.$refs.editGroupDropzone.dropzone.emit("complete", mockFile);
        } else {
          this.$refs.editGroupDropzone.removeAllFiles();
        }
      });
    },
    deleteQuestGroupOpen(id){
      this.deleteGroupId = id;
      this.$refs.PopupDeleteQuestGroup.open();
    },
    deleteQuestSingleOpen(id){
      this.deleteSingleId = id;
      this.$refs.PopupDeleteQuestSingle.open();
    },
    async deleteQuestGroupConfirm(){
      await this.deleteQuestGroup(this.deleteGroupId);
      this.successTitle = 'You quest group deleted successfully!';
      this.$refs.PopupSuccess.open();
    },
    async deleteQuestSingleConfirm(){
      await this.deleteQuestSingle(this.deleteSingleId);
      this.successTitle = 'You quest deleted successfully!';
      this.$refs.PopupSuccess.open();
    },
    async createSingleQuestConfirm(){
      if(this.selectedGroup){
        this.createSingleQuest.quest_group_id = this.selectedGroup.id;
      }else{
        this.createSingleQuest.quest_category_id = this.selectedCategory.id;
      }
      this.createSingleQuest.hotel_id = this.$store.state.objects.item.id;
      this.createSingleDialog = false;
      await this.createSingleQuestFunc(this.createSingleQuest);
      this.createSingleQuest = JSON.parse(JSON.stringify(this.defaultSingleQuest));
      this.successTitle = 'You quest created successfully!';
      this.$refs.PopupSuccess.open();
    },
    closeCreateSingle(){
      this.createSingleDialog = false;
      this.createSingleQuest = JSON.parse(JSON.stringify(this.defaultSingleQuest));
    },
    async createQuestGroupConfirm(){
        this.createGroupQuest.hotel_id = this.$store.state.objects.item.id;
        this.createGroupQuest.quest_category_id = this.selectedCategory.id;
        await this.createQuestGroup(this.createGroupQuest);
        this.createGroupDialog = false;
        this.createGroupQuest = JSON.parse(JSON.stringify(this.defaultGroupQuest));
        this.successTitle = 'You quest group created successfully!';
        this.$refs.PopupSuccess.open();
    },
    closeCreateGroupDialog(){
      this.createGroupDialog = false;
      this.createGroupQuest = JSON.parse(JSON.stringify(this.defaultGroupQuest));
    },
    formatDate(date){
      return moment(date).format('DD.MM.YYYY');
    },
    openAddSingleQuest(item){
      if(this.$refs.addSingleDropzone){
        this.$refs.addSingleDropzone.removeAllFiles();
      }
      if(this.$refs.addSingleDetailDropzone){
        this.$refs.addSingleDetailDropzone.removeAllFiles();
      }
      this.createSingleQuest = JSON.parse(JSON.stringify(this.defaultSingleQuest));
      if(item.id){
        this.selectedGroup = item;
        this.createSingleQuest.quest_group_id = this.selectedGroup.id;
      }
      this.createSingleDialog = true;
    },
    openAddGroupQuest(){
      if(this.$refs.addGroupDropzone){
        this.$refs.addGroupDropzone.removeAllFiles();
      }
      this.createGroupQuest = JSON.parse(JSON.stringify(this.defaultGroupQuest));
      this.createGroupDialog = true;
    },
    msToTime(ms){
      if(typeof ms === 'string'){
        return ms
      }else{
        const hours = Math.floor(ms/3600000);
        const minutes = Math.floor((ms - hours*3600000) / 60000);
        return `${hours}:${minutes}`;
      }
    }
  },
  async mounted() {
    if (this.$route.query.id) {
      const candidate = this.$store.state.objects.list.find(
          (x) => x.id === +this.$route.query.id
      );
      if (candidate) {
        this.$store.state.objects.item = candidate;
      } else {
        this.$store.state.objects.item = this.$store.state.objects.list[0];
      }
    } else {
      this.$store.state.objects.item = this.$store.state.objects.list[0];
    }
    await store.dispatch("landing/getHotelLangs", this.$store.state.objects.item.id);

    await this.getQuestCategories(this.$store.state.objects.item.id);
  }
}
</script>

<style lang="scss">
.card_body{
  padding:15px;
  &_item{
    margin-bottom:15px;
    & label{
      margin-bottom:10px;
    }
  }
}
.dz-size {
  display: none;
}
.dz-filename {
  display: none;
}
.vue-dropzone > .dz-preview .dz-image {
  max-width: 150px;
  max-height: 150px;
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}
.tariff_obj {
  margin-left:5px;
  display: inline-block;
  padding: 4px 6px;
  text-transform: uppercase;
  border-radius: 0 5px 0 5px;
  background: #007db5;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  color: #fff;
}
.copiedTelegram{
    position: fixed;
    left:calc(50% - 185px);
    top:5px;
    z-index: 10;
    width:370px;
}
</style>
